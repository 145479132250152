import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AppBack from './AppBack';
import KbanDashboard from './admin-pages/KbanDashboard';



if(document.getElementById('my-front')){
	ReactDOM.createRoot(document.getElementById('my-front')).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);
}

if(document.getElementById('my-back')){
	ReactDOM.createRoot(document.getElementById('my-back')).render(
	<React.StrictMode>
		<AppBack />
	</React.StrictMode>
	);
}
if(document.getElementById('kban-backend-dashboard')){
	ReactDOM.createRoot(document.getElementById('kban-backend-dashboard')).render(
	<React.StrictMode>
		<KbanDashboard />
	</React.StrictMode>
	);
}

