import { useState } from 'react';
import '../styles/dashboard.scss';

const KbanDashboard = () => {
    const [profileData,setProfileData] = useState(kban_profile_data);

    function mannageSubmit(e){
        e.preventDefault();

        wp.apiRequest({
            path:'kban/v1/kban-settings',
            method:'POST',
            data:{
                kban_settings: profileData
            }
          }).then(data => {
            console.log(data);
          }); 
   
    }

    function mannageInput(e){
        const {name,value} = e.target;
        const nuevoEstado = {...profileData};
        nuevoEstado[name] = parseInt(value);
        setProfileData(nuevoEstado);
    }


    return ( 
    <div>
        <h3>KBAN PROFILE</h3>
        <p>Aquí puedes administrar el perfil de Kban.</p>
        <div>
            <form onSubmit={e => mannageSubmit(e)}>
                <div className="form-group">
                    <div className="row">
                        <div className="col-6">
                            <input type="number" className="form-control" name="kban_cash" id="kban_cash" value={profileData.kban_cash} onChange={e => mannageInput(e)}  /> 
                            
                        </div>
                        <div className="col-6">
                            <span className="kban-data-view">
                               
                            </span>
                        </div>
                    </div>                    
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-6">
                            <input type="number" className="form-control" name="kban_customer_rut" id="kban_customer_rut" value={profileData.kban_customer_rut}  onChange={e => mannageInput(e)}/> 
                        </div>
                        <div className="col-6">
                            <span className="kban-data-view">
                              
                            </span>
                        </div>
                    </div>
                    
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-6">
                            <input type="number" className="form-control" name="kban_customer_phone" id="kban_customer_phone" value={profileData.kban_customer_phone} onChange={e => mannageInput(e)} /> 
                       </div>
                        <div className="col-6">
                            <span className="kban-data-view">
                               
                            </span>
                        </div>
                    </div>
                    
                </div>
                
                <p className="submit">
                    <input type="submit" className="button button-primary" value="save" />
                </p>
                
            </form>
        </div> 
    </div> 
    );
}
 
export default KbanDashboard;